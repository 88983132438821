import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
import {
    FirstScreen,
    ServicesAdvantages,
    HowItWorksHome,
    Components,
    StagesOfWork,
    InteractiveHome,
    Garants
} from '../components/ServicesAndPrices'
import { FormOrder, Breadcrumbs } from '../components/Global'
// content
import YAMLGlobal from "../content/ru/global.yaml"
import YAMLService from "../content/ru/service.yaml"

const ServicesHomePage = ({location}) => {
    const serviceLocal = {
        langBase: "",
        YAMLGlobal,
        YAMLContent: YAMLService
    }
    
    const data = useStaticQuery(graphql`
        query {
            seo: directusPricesHomeSeo {
                title
                description
            }
            firstscreen: allDirectusPricesFirstscreen {
                edges {
                    node {
                        home_description
                        home_title
                        home_image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1600) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            advantages: allDirectusPricesHomeAdvantage {
                edges {
                    node {
                        id
                        title
                        description
                    }
                }
            },
            slides: allDirectusPricesHomeComponent {
                edges {
                    node {
                        id
                        description
                        title
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        logos {
                            id
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            stages: allDirectusStepsWorkRu(sort: {fields: sort}) {
                edges {
                    node {
                        id
                        list
                        sort
                        title
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 700) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            interactive: allDirectusPricesservicesreserve {
                edges {
                    node {
                        tariff_ee_1
                        euro
                        dollar
                        green_tariff_euro
                        _5kvt
                        _8kvt
                        _10kvt
                        _12kvt
                        _15kvt
                        _17kvt
                        _20kvt
                        _25kvt
                        _30kvt
                        _36kvt
                    }
                }
            },
            garants: allDirectusPricesGuarantee {
                edges {
                    node {
                        id
                        description
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: '/services-home',
            title: YAMLGlobal.menu.services.home
        }
    ]
    
    return(
        <Layout local={serviceLocal} location={location}>
            <SEO title={data.seo.title} description={data.seo.description} />
            <Breadcrumbs list={list}/>
            <FirstScreen content={{
                title: data.firstscreen.edges[0].node.home_title,
                text: data.firstscreen.edges[0].node.home_description,
                img: data.firstscreen.edges[0].node.home_image.localFile && data.firstscreen.edges[0].node.home_image.localFile?.childImageSharp.fluid
            }} />
            <ServicesAdvantages advantages={data.advantages.edges}/>
            <HowItWorksHome/>
            <Components slides={data.slides.edges} />
            <InteractiveHome interactive={data.interactive.edges[0].node}/>
            <StagesOfWork stages={data.stages.edges}/>
            <Garants garants={data.garants.edges}/>
            <FormOrder wave="white"/>
        </Layout>
    )
}

export default ServicesHomePage;